<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.training_summary_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <searchBasic :search="search" @search-data="searchIt"/>
                  <b-col lg="12" sm="12" class="text-right">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
              <b-row v-if='datas.length > 0'>
                <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('tpm_report.training_summary_report') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" @click="pdfExport">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loadingData">
                        <b-row mt-5>
                          <b-col md="12" class="table-responsive">
                            <div style="border: 2px solid;margin:10px;padding:10px">
                              <b-row>
                                <b-col>
                                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                    <template v-slot:projectNameSlot>
                                      {{ }}
                                    </template>
                                    {{ $t('tpm_report.training_summary_report') }}
                                  </list-report-head>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <div class="text-center">
                                    <table style="width:100%;color:black;">
                                      <tr v-if="search.fiscal_year">
                                        <td align="left" style="width:17%">{{ $t('elearning_config.fiscal_year') }}</td>
                                        <td align="center" style="width:2%">:</td>
                                        <td align="left" style="width:81%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                      </tr>
                                      <tr v-if="search.org_id">
                                        <td align="left" style="width:17%">{{ $t('elearning_config.organization') }}</td>
                                        <td align="center" style="width:2%">:</td>
                                        <td align="left" style="width:81%">{{ ($i18n.locale==='bn') ? search.org_name_bn : search.org_name }}</td>
                                      </tr>
                                      <tr v-if="search.training_type">
                                        <td align="left" style="width:17%">{{ $t('elearning_config.training_type') }}</td>
                                        <td align="center" style="width:2%">:</td>
                                        <td align="left" style="width:81%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                                      </tr>
                                      <tr v-if="search.training_category">
                                        <td align="left" style="width:17%">{{ $t('elearning_config.training_category') }}</td>
                                        <td align="center" style="width:2%">:</td>
                                        <td align="left" style="width:81%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                      </tr>
                                      <tr v-if="search.training_title">
                                        <td align="left" style="width:17%">{{ $t('elearning_config.training_title') }}</td>
                                        <td align="center" style="width:2%">:</td>
                                        <td align="left" style="width:81%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                      </tr>
                                      <tr v-if="search.batch_no">
                                        <td align="left" style="width:17%">{{ $t('elearning_iabm.batch_no') }}</td>
                                        <td align="center" style="width:2%">:</td>
                                        <td align="left" style="width:81%">{{ ($i18n.locale==='bn') ? search.batch_name_bn : search.batch_name }}</td>
                                      </tr>
                                      <tr v-if="search.circular_memo_no">
                                        <td align="left" style="width:17%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                        <td align="center" style="width:2%">:</td>
                                        <td align="left" style="width:81%">{{ search.circular_memo_no }}</td>
                                      </tr>
                                      <tr v-if="search.training_quarter_id">
                                        <td align="left" style="width:17%">{{ $t('elearning_config.training_quarter_name') }}</td>
                                        <td align="center" style="width:2%">:</td>
                                        <td align="left" style="width:81%">{{ ($i18n.locale==='bn') ? search.quaeter_name_bn : search.quaeter_name }}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length > 0">
                                    <b-thead>
                                      <b-tr>
                                        <b-th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th class="text-center">{{$t('elearning_config.training_title')}}</b-th>
                                        <b-th class="text-center">{{$t('elearning_iabm.batch_no')}}</b-th>
                                        <b-th style="width:10%" class="text-center">{{ $t('elearning_iabm.circular_memo_no') }}</b-th>
                                        <b-th class="text-center">{{$t('globalTrans.start_date')}}</b-th>
                                        <b-th class="text-center">{{$t('globalTrans.end_date')}}</b-th>
                                        <b-th class="text-center">{{$t('tpm_report.total_cader')}}</b-th>
                                        <b-th class="text-center">{{$t('tpm_report.total_non_cader')}}</b-th>
                                        <b-th class="text-center">{{$t('tpm_report.male_trainer')}}</b-th>
                                        <b-th class="text-center">{{$t('tpm_report.female_trainer')}}</b-th>
                                        <b-th class="text-center">{{$t('tpm_report.male_trainee')}}</b-th>
                                        <b-th class="text-center">{{$t('tpm_report.female_trainee')}}</b-th>
                                        <b-th class="text-center">{{$t('tpm_report.numbe_of_trainer')}}</b-th>
                                        <b-th class="text-center">{{$t('tpm_report.numbe_of_trainee')}}</b-th>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody v-for="(info, index) in datas" :key="index">
                                      <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.training_title_bn : info.training_title }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.batch_name_bn : info.batch_name }}</b-td>
                                      <b-td class="text-center">{{ info.circular_memo_no }}</b-td>
                                      <b-td class="text-center">{{ info.training_start_date | dateFormat }}</b-td>
                                      <b-td class="text-center">{{ info.training_end_date | dateFormat }}</b-td>
                                      <b-td class="text-center">
                                          <slot v-if="info.cader_count > 0">
                                            {{ $n(info.cader_count, { useGrouping: false }) }}
                                          </slot>
                                          <slot v-else>-</slot>
                                       </b-td>
                                      <b-td class="text-center">
                                          <slot v-if="info.non_cader_count > 0">
                                            {{ $n(info.non_cader_count, { useGrouping: false }) }}
                                          </slot>
                                          <slot v-else>-</slot>
                                       </b-td>
                                      <b-td class="text-center">{{ $n(info.male_trainer, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center">{{ $n(info.female_trainer, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center">{{ $n(info.male_trainee, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center">{{ $n(info.female_trainee, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center">{{ $n(info.trainer_count, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center">{{ $n(info.trainee_count, { useGrouping: false }) }}</b-td>
                                    </b-tbody>
                                  </b-table-simple>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingSummryReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
import searchBasic from './../../../component/searchBasic'
Vue.use(excel)

export default {
  props: ['id'],
  components: {
    ListReportHead,
    searchBasic
  },
  created () {
    this.getCircularList()
    // this.search = Object.assign({}, this.search, {
    //   org_id: this.$store.state.dataFilters.orgId,
    //   fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    // })
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      circularLoading: false,
      search: {
        org_id: 0,
        fiscal_year_id: 0,
        circular_memo_no: 0,
        training_type_id: 0,
        training_category_id: 0,
        training_title_id: 0,
        batch_no: 0,
        course_module_id: 0
      },
      trainingCategoryList: [],
      trainingTitleList: [],
      trainerNameList: [],
      trainerList: [],
      circularList: [],
      allBatchListData: [],
      trainingTypeList: [],
      batchList: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      datas: [],
      loading: false,
      loadingData: false,
      showData: false
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  computed: {
     allBatchList () {
      const batchList = [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
      return batchList
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    i18 () {
      return this.$i18n.locale
    }
  },
  watch: {
    'search.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'search.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    searchIt (search) {
      this.search = search
    },
    pdfExport () {
        const reportTitle = this.$t('tpm_report.training_summary_report')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
    },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async searchData () {
      this.loadingData = true
      this.showData = true
      this.getCustomDataSearch()
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingSummryReport, this.search)
      if (result.success) {
        if (result.data) {
          this.loadingData = false
          this.datas = this.getCustomDataList(result.data)
        }
      } else {
        this.datas = []
        this.loadingData = false
      }
    },
    getCustomDataSearch () {
      const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
      if (typeof fiscalYearObj !== 'undefined') {
        this.search.fiscal_year = fiscalYearObj.text_en
        this.search.fiscal_year_bn = fiscalYearObj.text_bn
      //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
      } else {
        this.search.fiscal_year = ''
        this.search.fiscal_year_bn = ''
      }
      const batchObj = this.allBatchList.find(item => item.value === this.search.batch_no)
      this.search.batch_name = typeof batchObj !== 'undefined' ? batchObj.text_en : ''
      this.search.batch_name_bn = typeof batchObj !== 'undefined' ? batchObj.text_bn : ''

      const orgObj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === this.search.org_id)
      this.search.org_name = typeof orgObj !== 'undefined' ? orgObj.text_en : ''
      this.search.org_name_bn = typeof orgObj !== 'undefined' ? orgObj.text_bn : ''

      const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === this.search.training_type_id)
      this.search.training_type = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_en : ''
      this.search.training_type_bn = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_bn : ''

      const trainingCategoryListObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === this.search.training_category_id)
      this.search.training_category = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_en : ''
      this.search.training_category_bn = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_bn : ''

      const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === this.search.training_title_id)
      this.search.training_title = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_en : ''
      this.search.training_title_bn = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_bn : ''

      const tQuarterObj = this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.find(trainingQuarterSetupList => trainingQuarterSetupList.value === parseInt(this.search.training_quarter_id))
      if (typeof tQuarterObj !== 'undefined') {
        this.search.quaeter_name = tQuarterObj.text_en
        this.search.quaeter_name_bn = tQuarterObj.text_bn
      } else {
        this.search.quaeter_name = ''
        this.search.quaeter_name_bn = ''
      }
    },
    getCustomDataList (data) {
      const listData = data.map(item => {
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
        const fiscalYearData = {}
        if (typeof fiscalYearObj !== 'undefined') {
          fiscalYearData.fiscal_year = fiscalYearObj.text_en
          fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          fiscalYearData.fiscal_year = ''
          fiscalYearData.fiscal_year_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        const trainingTitleData = {}
        if (typeof trainingTitleObj !== 'undefined') {
          trainingTitleData.training_title = trainingTitleObj.text_en
          trainingTitleData.training_title_bn = trainingTitleObj.text_bn
        } else {
          trainingTitleData.training_title = ''
          trainingTitleData.training_title_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        const trainingTypeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          trainingTypeData.training_type = trainingTypeObj.text_en
          trainingTypeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          trainingTypeData.training_type = ''
          trainingTypeData.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
        const trainingCategoryData = {}
        if (typeof trainingCategoryObj !== 'undefined') {
          trainingCategoryData.training_category = trainingCategoryObj.text_en
          trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          trainingCategoryData.training_category = ''
          trainingCategoryData.training_category_bn = ''
        }
        const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
        const batchData = {}
        if (typeof batchObj !== 'undefined') {
          batchData.batch_name = batchObj.text_en
          batchData.batch_name_bn = batchObj.text_bn
        } else {
          batchData.batch_name = ''
          batchData.batch_name_bn = ''
        }
        return Object.assign({}, item, fiscalYearData, trainingTitleData, trainingTypeData, trainingCategoryData, batchData)
      })
      return listData
    }
  }
}
</script>
